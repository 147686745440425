import { MySituationElement } from "../../../models/umbracoElement";
import { UpdateWorkDetailsForm } from "../UpdateWork/UpdateWorkDetailsForm";
import { Stack } from "@mui/material";
import { SantizedRichHtml } from "../../_GenericElements/Richtext/richtext";

interface MySituationProps {
    data: string,
    showworking: boolean
}
export const MySituation = (props: MySituationProps) => {
    const umbracoSettings: MySituationElement = JSON.parse(props.data);

    return <Stack direction="column" spacing={4}>
        <div>
            <h2>{umbracoSettings.text || "Min situation"}</h2>
            {SantizedRichHtml(umbracoSettings.richtext || "Her kan du opdatere din situation")}
            {props.showworking ? <UpdateWorkDetailsForm></UpdateWorkDetailsForm> : ""}
        </div>
    </Stack>;

};