/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Dispatch, SetStateAction, useEffect, useState, useMemo } from "react";
import { Form, Formik } from "formik";
import style from "./master-data.module.scss";
import { ContactUpdate } from "../../../api/models/ContactUpdate";
import { ContactsService } from "../../../api";
import * as api from "../../../api";
import Loader from "../../_GenericElements/Loader/loader";
import { MasterDataUpdateElement } from "../../../models/umbracoElement";
import { GenericFormElements } from "../../_GenericElements/_GenericFormElements/GenericFormElements";
import { masterDataUpdateFormLabels } from "./master-data-labels";
import { SantizedRichHtml } from "../../_GenericElements/Richtext/richtext";
import { MessageDiv, MessageType } from "../../_GenericElements/MessageDiv/MessageDiv";
import { UpdateConfirmMessageBox } from "../UpdateConfirmMessageBox/UpdateConfirmMessageBox";
import { contactUpdateSchema } from "./MasterDataSchema";

export interface IMasterDataUpdateProps {
    data: string;
}

export const MasterDataUpdate = (props: IMasterDataUpdateProps) => {
    const [umbracoSettings] = useState<MasterDataUpdateElement>(props.data ? JSON.parse(props.data) : {});
    const [isSubmitting, setSubmitting] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [showConfirm, setShowConfirm] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [formValues, setFormValues]:
        [ContactUpdate, Dispatch<SetStateAction<ContactUpdate>>] = useState({});

    useEffect(() => {
        void getCurrentData();
    }, []);

    const getCurrentData = async () => {
        setLoading(true);
        const contact = await api.ContactsService.getContact();

        setFormValues({
            email: contact.email ?? "",
            mobilePhone: contact.mobilePhone,
            language: contact.language,
        });
        setLoading(false);
    };

    const updateContact = async (formData: ContactUpdate) => {
        try {
            let response = await ContactsService.updateContact(formData);

            setShowConfirm(true);

            return response;
        } catch (err) {
            if (err instanceof Error && err.message && (err.message === "Client Error")) {
                setErrorMsg("Ændringen kan desværre ikke gennemføres. Kontakt os venligst via telefon eller en besked her på Mit DM for at informere os om din ændring.");
            } else {
                setErrorMsg("Der skete en uventet fejl og ændringen er ikke gennemført. Prøv venligst igen senere og kontakt os ved gentagen fejl.");
            }
            Object.assign(formData, formValues);
            console.error(err);
        }
    };

    const FormElements = new GenericFormElements<ContactUpdate>(masterDataUpdateFormLabels);

    const masterDataUpdateForm = useMemo(() => (
        <Formik
            initialValues={formValues}
            onSubmit={async (values) => {
                setSubmitting(true);
                await updateContact(values);
                setSubmitting(false);
            }}
            validationSchema={contactUpdateSchema}
        >
            {({ errors, touched }) => {
                return <Form className={style.dmForm}>
                    <FormElements.BasicTextInput formValueName="email" />

                    <FormElements.BasicTextInput formValueName="mobilePhone" />

                    {FormElements.EnumDropDown("language", ContactUpdate.language)}

                    <FormElements.SubmitButton disabled={isSubmitting} loading={isSubmitting} type="submit">
                        {umbracoSettings.submitButtonLabel || ""}
                    </FormElements.SubmitButton>
                </Form>;
            }
            }
        </Formik>), [formValues, isSubmitting]);

  return (<div className={style.root}>
    {
        isLoading ?
            <Loader></Loader> :
            <div>
                <h2>{umbracoSettings.text || "Kontaktoplysninger"}</h2>
                {umbracoSettings.contentText && SantizedRichHtml(umbracoSettings.contentText)}
                {masterDataUpdateForm}
                {showConfirm && <UpdateConfirmMessageBox onClose={() => setShowConfirm(false)} title={`${umbracoSettings.text || "Kontaktoplysninger"} ændring`} />}
                <MessageDiv type={MessageType.error} msg={errorMsg} />
            </div>
    }
  </div>);
};

export default MasterDataUpdate;