/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useCallback, useEffect } from "react";
import { MembershipService, ContactsService, ApiError, AgreementInformation, Benefit } from "../../../api";
import Loader from "../../_GenericElements/Loader/loader";
import LabelValue from "../../_GenericElements/LabelValue/label-value";
import * as style from "./membership-overview.module.scss";
import { DanishDateFns } from "../../../helpers/DanishDateFns";
import { AgreementInfoElement } from "../../../models/umbracoElement";
interface MemberOverviewProps {
    data: string
}

export const MemberOverview = (props: MemberOverviewProps) => {
    const [umbracoSettings]=React.useState<AgreementInfoElement>(JSON.parse(props.data||"{}"));
    const [data, setData] = React.useState<AgreementInformation>();
    const [benefitData, setBenefitData] = React.useState<Benefit[]>([]);
    const [isMember, setIsMember] = React.useState<boolean>(true);

    useEffect(() => {
        void (async () => {
            try {
                let Data = await MembershipService.getMembership();
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                const benefitData = await ContactsService.getBenefits();
                setData(Data);
                setBenefitData(benefitData);
            } catch (e) {
                if (e instanceof ApiError) {
                    if (e.status == 500) {
                        console.log("Finder ingen medlemskab");
                        setIsMember(false);
                    }
                }
            }
        })();
    }, []);
    const getBenefitPrice=useCallback((price?:number|null,endDate?:string|null)=>{
        const enddatetext=endDate&&umbracoSettings.showEndingDate?` Ophører ${DanishDateFns.format(new Date(endDate),"dd.MM.yy")}`:"";
        if (!price||price==0){
            return `${umbracoSettings.textOnFreeOfCharge||""}${enddatetext}`;
        }
        return `${createValueWithUnit(new Intl.NumberFormat("da-DK").format(price)
            .toString())}${enddatetext?`(${enddatetext})`:""}`;
    },[umbracoSettings]);
   
    const createValueWithUnit=useCallback((val:string)=>{
        if (!val) {return "";}
        return `${val} ${umbracoSettings.priceUnit||"kroner pr kvartal"}`;
    },[umbracoSettings]);

    if (!data) {
        if (isMember) {
            return <Loader />;
        } else {
            return <div></div>;
        }
    }
    

    return <div>
        <h2>
            Kontingent
        </h2>
        <table className={style.membershipinfo}>
            <LabelValue hideIfEmpty={true} label="Medlemsnummer: " value={data.memberId}></LabelValue>
            <LabelValue hideIfEmpty={true} label="Kontingent: " value={createValueWithUnit(data.agreementPrice ? new Intl.NumberFormat("da-DK").format(data.agreementPrice)
                .toString() : "")}></LabelValue>
            <LabelValue hideIfEmpty={false} label="Ikrafttrædelse for kontingentændring: " 
                value={data.firstPaymentDate ? DanishDateFns.format(new Date(data.firstPaymentDate),"dd.MM.yy") : ""}/>
            {benefitData.map((x: Benefit, i: number) =>
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                <LabelValue key={`benefit-${i}`} hideIfEmpty={false} label={`${x.name || "-"}:`}
                    value={getBenefitPrice(x.quarterlyPrice,x.endDate)}
                />)
            }
            <LabelValue hideIfEmpty={true} label="Kontingentgruppe: " value={data.agreementGroup}></LabelValue>
        </table>
    </div>;

};