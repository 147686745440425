/* eslint-disable */

import { useState } from "react";
import { CrmEntity } from "../../api/models/CrmEntity";
import { GenericFormElements } from "./_GenericFormElements/GenericFormElements";
import { Form, Formik, FormikHelpers, FormikValues } from "formik";

type ComponentDemo = {
    data: string;
}

export const ComponentDemo = (props: ComponentDemo) => {
  const formElements = new GenericFormElements({});
  const [workAreas, setWorkAreas] = useState<NonNullable<CrmEntity[]>>([{ primaryName: "Test 1", id: "0" }, { primaryName: "Test 2", id: "1" }]);
    return (
        <Formik 
        onSubmit={() => { } } initialValues={{}}>
          <Form >
        <h2>{"Version 2 Design Demo"}</h2>
          
          <h3>{"Text"}</h3>
          <formElements.BasicTextInput formValueName="text" />
          <h3>{"Checkbox"}</h3>
          <formElements.BasicYesNoSwitch formValueName="checkbox" />
          <h3>{"Date"}</h3>
          <formElements.BasicDateInput formValueName="date" />
          <h3>{"Search"}</h3>
          <formElements.RecordAutoComplete formValueName="search"  options={workAreas}  loading={false} />
          <h3>{"Select"}</h3>
          {formElements.EnumDropDown("select", MainEducationUpdate)}
          
          </Form>
        </Formik>
    );

};

enum MainEducationUpdate {
  Student = "student",
  HighSchool = "highschool",
  Academy = "academy",
}