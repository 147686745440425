
import "./global.scss";
import reportWebVitals from "./reportWebVitals";
import Richtext from "./components/_GenericElements/Richtext/richtext";
import { AvailableForm, ChangeToParentalLeaveForm, RetirementForm, ChangeToWorkingForm } from "./components/PaymentGroup/PaymentGroupForms";
import { MasterDataRead } from "./components/Membership/MasterData/master-data-read";
import { Login } from "./components/UserAccount/Login/login";
import MasterDataUpdate from "./components/Membership/MasterData/master-data-update";
import { EventList } from "./components/Event/EventList/event-list";
import { EventOverview } from "./components/Event/EventOverview/event-overview";
import EducationForm from "./components/Membership/UpdateEducation/EducationForm";
import { IncidentOverview } from "./components/Incidents/incident-overview";
import { UmbracoIncidentList } from "./components/Incidents/incident-list";
import { IncidentForm } from "./components/Incidents/incident-form";
import { GeckoCalender } from "./components/GeckoCalender/gecko-calender";
import { CTACard } from "./components/CTACard/CTACard";
import { EndMembershipForm } from "./components/Membership/EndMembershipForm/EndMembershipForm";
import { Logout } from "./components/PageLayout/Logout";
import { ToHomePage } from "./components/PageLayout/ToHomePage";
import { MegaMenu } from "./components/PageLayout/MegaMenu";
import { NavBar } from "./components/PageLayout/NavBar";
import { RepresentativeCases } from "./components/RepresentativeOverview/Cases/representative-cases";
import { RepresentativeOverview } from "./components/Representative/RepresentativeOverview/representative-overview";
import { RepresentativeSubmissionForm } from "./components/Representative/RepresentativeSubmissionForm/representative-submission-form";
import { NewsSubscriptionsForm } from "./components/Membership/NewsSubscriptions/news-subscriptions-form";
import { HeaderLogo } from "./components/PageLayout/HeaderLogo";
import { RootedReactElement } from "./components/RootElements/RootedReactComponent";
import { RepresentativeList } from "./components/Representative/RepresentativeList/representative-list";
import { MemberOverview } from "./components/Membership/ShowMembership/membership-overview";
import { MemberCard } from "./components/Membership/MemberCard/member-card";
import { PrintedMagazinesForm } from "./components/Membership/PrintedMagazines/printed-magazines-form";

import { EndMembershipInfoComponent } from "./components/Membership/EndMembershipInfo/end-membership-info";
import { NewsSubscriptionsInfo } from "./components/Membership/NewsSubscriptions/news-subscriptions-info";
import { PbsRegistration } from "./components/PbsRegistration/pbs-registration";
import { MySituation } from "./components/Membership/MySituation/my-situation";
import { Menu } from "./components/PageLayout/Menu";
import { WorkTimeForm } from "./components/PaymentGroup/WorkTimeForm/WorkTimeForm";
import { ChangeToFreelanceForm } from "./components/PaymentGroup/FreelanceIndependentForm/FreelanceIndependentForm";
import { SectorInterestsForm } from "./components/Membership/SectorInterests/sector-interests-form";
import { RightArrow } from "./components/_GenericElements/RightArrow/right-arrow";
import { NetworkList } from "./components/Membership/Network/network-list";
import { CTAWithPicture } from "./components/_GenericElements/CTAWithPicture/CTAWithPicture";
import { MasterDataSectorForm } from "./components/Membership/MaterDataSector/master-data-sector-form";
import { ShowPoliticalGroups } from "./components/Membership/PoliticalGroups/show-political-groups";
import { EventContactInformation } from "./components/Event/EventOverview/event-contact-information";
import { PillarMembershipsForm } from "./components/Membership/PillarMemberships/pillar-memberships-form";
import { EducationViewForm } from "./components/Membership/ViewEducation/EducationViewForm";
import { ConsentForm } from "./components/Membership/Consents/consent-form";
import { ConsentList } from "./components/Membership/Consents/consent-list";
import { MasterDataAddressRead } from "./components/Membership/MasterData/master-data-address-read";
import { ComponentDemo } from "./components/_GenericElements/ElementDemo";

// Event components
customElements.define("dm-event-overview", RootedReactElement(EventOverview, ["coursenumber", "data"]));
customElements.define("dm-event-list", RootedReactElement(EventList, ["data"]));


// Paymentgroup components
customElements.define("dm-parental-leave-form", RootedReactElement(ChangeToParentalLeaveForm, ["data"]));
customElements.define("dm-work-form", RootedReactElement(ChangeToWorkingForm, ["data"]));
customElements.define("dm-retirement-form", RootedReactElement(RetirementForm, ["data"]));
customElements.define("dm-available-form", RootedReactElement(AvailableForm, ["data"]));
customElements.define("dm-change-worktime-form", RootedReactElement(WorkTimeForm, ["data"]));
customElements.define("dm-freelance-independent-form", RootedReactElement(ChangeToFreelanceForm, ["data"]));


// UserAccount components
customElements.define("dm-login", RootedReactElement(Login, ["data", "showemaillogin"]));
customElements.define("dm-logout", RootedReactElement(Logout, ["data"]));

// PageLayout components
customElements.define("dm-menu", RootedReactElement(Menu, ["org", "includeMenu", "data"]));
customElements.define("dm-homepage", RootedReactElement(ToHomePage, ["data"]));
customElements.define("dm-nav-bar", RootedReactElement(NavBar, ["data"]));
customElements.define("dm-header-logo", RootedReactElement(HeaderLogo, ["src"]));
customElements.define("dm-mega-menu", RootedReactElement(MegaMenu, ["data"]));

// Representative components
customElements.define("dm-representative-overview", RootedReactElement(RepresentativeOverview, ["id", "data", "contactid", "type"]));
customElements.define("dm-representative-list", RootedReactElement(RepresentativeList, ["id", "data", "trid"]));
customElements.define("dm-representative-submit-form", RootedReactElement(RepresentativeSubmissionForm, ["data", "id"]));



// Membership components
customElements.define("dm-master-data-address-read", RootedReactElement(MasterDataAddressRead, ["data"]));
customElements.define("dm-master-data", RootedReactElement(MasterDataUpdate, ["data"]));
customElements.define("dm-education-form", RootedReactElement(EducationForm, ["data","organisation"]));
customElements.define("dm-education-view-form", RootedReactElement(EducationViewForm, ["data","organisation"]));
customElements.define("dm-news-subscription-form", RootedReactElement(NewsSubscriptionsForm, ["data"]));
customElements.define("dm-printed-magazines-form", RootedReactElement(PrintedMagazinesForm, ["data"]));
customElements.define("dm-pillar-memberships-form", RootedReactElement(PillarMembershipsForm, ["data"]));
customElements.define("dm-profile", RootedReactElement(MasterDataRead, ["data"]));
customElements.define("dm-end-membership-info", RootedReactElement(EndMembershipInfoComponent, ["data"]));
customElements.define("dm-member-card", RootedReactElement(MemberCard, ["data"]));
customElements.define("dm-end-membership", RootedReactElement(EndMembershipForm, ["data"]));
customElements.define("dm-agreement-info", RootedReactElement(MemberOverview, ["data"]));
customElements.define("dm-news-subscriptions-info", RootedReactElement(NewsSubscriptionsInfo, ["data"]));
customElements.define("dm-my-situation", RootedReactElement(MySituation, ["data", "showworking", "showparentalleave"]));
customElements.define("dm-sector-interests", RootedReactElement(SectorInterestsForm, ["data"]));
customElements.define("dm-network-list", RootedReactElement(NetworkList, ["data"]));
customElements.define("dm-master-data-sector-form", RootedReactElement(MasterDataSectorForm, ["data"]));
customElements.define("dm-political-groups", RootedReactElement(ShowPoliticalGroups, ["data"]));
customElements.define("dm-consent-form", RootedReactElement(ConsentList, ["data"]));


// Incident components

customElements.define("dm-incident-overview", RootedReactElement(IncidentOverview, ["data", "name", "incidentnumber"]));
customElements.define("dm-incident-list", RootedReactElement(UmbracoIncidentList, ["data"]));
customElements.define("dm-incident-form", RootedReactElement(IncidentForm, ["data"]));

customElements.define("dm-gecko-calender", RootedReactElement(GeckoCalender, ["data"]));
customElements.define("dm-cta-card", RootedReactElement(CTACard, ["data", "disabled"]));
customElements.define("dm-richtext", RootedReactElement(Richtext, ["data"]));
customElements.define("dm-cta-with-picture", RootedReactElement(CTAWithPicture, ["data"]));
customElements.define("dm-pbs-registration", RootedReactElement(PbsRegistration, ["data"]));

customElements.define("dm-representative-cases", RootedReactElement(RepresentativeCases, ["data"]));

customElements.define("dm-right-arrow", RootedReactElement(RightArrow, ["data"]));

//Demo
customElements.define("dm-demo-ui", RootedReactElement(ComponentDemo,[])); 

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


const { fetch: originalFetch } = window;

window.fetch = async (...args) => {
    let [resource, config] = args;
    // request interceptor here
    try {
        const response = await originalFetch(resource, config);
        // response interceptor here
        if (response.status === 401) {
            const returnurl = window.location.href.replace(`https://${window.location.host}`, "");
            window.location.href = `https://${window.location.host}/login/?ReturnUrl=${encodeURIComponent(returnurl)}`;
        }
        return response;
    } catch (err) {
        console.log("Error n window.fetch - custom interceptor", err);
        throw err;
    }
};
